$primaryColor: #9e292b;
$primaryColorOverlay: rgba(158, 41, 43, 0.12);
$secondaryColor: #3a424b;
$accentColor: #e98404;
$grayDarkColor: #3a424b;

$logoGradient: linear-gradient(35deg, #9e292b 35%, #f6a800);

$colorBeige: #e6e2e2;
$colorGrey: rgba(232, 232, 232, 0.5);

$logoBackground: url("/images/logo/cloud-transparent-mono.svg") no-repeat center;
$logoBackgroundTablet: url("/images/logo/cloud-transparent-mono-48-48.svg")
	no-repeat center;
$logoBackgroundMobile: url("/images/logo/logo-dbildungsMobile.svg") no-repeat
	center;
$navbarLogo: url("/images/logo/navbar-cloud-logo.svg") center / contain no-repeat;

aside.nav-sidebar {
	.sc-branding {
		padding: 0;
		.cloud-logo {
			width: 100%;
			margin: 0;
			padding: 30px;
		}
		.sc-title {
			display: none;
		}
	}

	@media (max-width: 991px) {
		.sc-branding {
			padding: 0;
			.cloud-logo {
				background: $logoBackgroundTablet !important;
				height: 32px;
			}
		}
	}
}

@media (max-width: 575px) {
	.cloud-logo {
		background: $logoBackgroundMobile !important;
	}
}

.primary-color {
	color: $primaryColor;
	display: none;
}
.secondary-color {
	color: $secondaryColor;
	display: none;
}
@font-face {
	font-family: 'PT Sans';
	src: url('../../fonts/ptsans/ptsans-regular.eot?v=1.0.0');
	src: url('../../fonts/ptsans/ptsans-regular.eot?v=1.0.0#iefix') format('embedded-opentype'),
		 url('../../fonts/ptsans/ptsans-regular.woff2?v=1.0.0') format('woff2'),
		 url('../../fonts/ptsans/ptsans-regular.woff?v=1.0.0') format('woff'),
		 url('../../fonts/ptsans/ptsans-regular.ttf?v=1.0.0') format('truetype'),
		 url('../../fonts/ptsans/ptsans-regular.svg?v=1.0.0#pt_sansregular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'PT Sans';
	src: url('../../fonts/ptsans/ptsans-italic.eot?v=1.0.0');
	src: url('../../fonts/ptsans/ptsans-italic.eot?v=1.0.0#iefix') format('embedded-opentype'),
		 url('../../fonts/ptsans/ptsans-italic.woff2?v=1.0.0') format('woff2'),
		 url('../../fonts/ptsans/ptsans-italic.woff?v=1.0.0') format('woff'),
		 url('../../fonts/ptsans/ptsans-italic.ttf?v=1.0.0') format('truetype'),
		 url('../../fonts/ptsans/ptsans-italic.svg?v=1.0.0#pt_sansregular') format('svg');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'PT Sans';
	src: url('../../fonts/ptsans/ptsans-bold.eot?v=1.0.0');
	src: url('../../fonts/ptsans/ptsans-bold.eot?v=1.0.0#iefix') format('embedded-opentype'),
		 url('../../fonts/ptsans/ptsans-bold.woff2?v=1.0.0') format('woff2'),
		 url('../../fonts/ptsans/ptsans-bold.woff?v=1.0.0') format('woff'),
		 url('../../fonts/ptsans/ptsans-bold.ttf?v=1.0.0') format('truetype'),
		 url('../../fonts/ptsans/ptsans-bold.svg?v=1.0.0#pt_sansregular') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'PT Sans';
	src: url('../../fonts/ptsans/ptsans-bold-italic.eot?v=1.0.0');
	src: url('../../fonts/ptsans/ptsans-bold-italic.eot?v=1.0.0#iefix') format('embedded-opentype'),
		 url('../../fonts/ptsans/ptsans-bold-italic.woff2?v=1.0.0') format('woff2'),
		 url('../../fonts/ptsans/ptsans-bold-italic.woff?v=1.0.0') format('woff'),
		 url('../../fonts/ptsans/ptsans-bold-italic.ttf?v=1.0.0') format('truetype'),
		 url('../../fonts/ptsans/ptsans-bold-italic.svg?v=1.0.0#pt_sansregular') format('svg');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}
